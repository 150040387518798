html {
	font-size: $base-font-size;
}

body {
	font-family: $base-font-family;
	line-height: $base-line-height;
}

h1 {
	@include calc-rem($base-font-context * 2.5);
}

h2 {
	@include calc-rem($base-font-context * 2);
}

h3 {
	@include calc-rem($base-font-context * 1.75);
}

h4 {
	@include calc-rem($base-font-context * 1.5);
}

h5 {
	@include calc-rem($base-font-context * 1.25);
}

h6 {
	@include calc-rem($base-font-context * 1);
}
