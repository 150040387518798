/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$-credit-arrow-name: '_credit_arrow';
$-credit-arrow-x: 232px;
$-credit-arrow-y: 187px;
$-credit-arrow-offset-x: -232px;
$-credit-arrow-offset-y: -187px;
$-credit-arrow-width: 18px;
$-credit-arrow-height: 18px;
$-credit-arrow-total-width: 266px;
$-credit-arrow-total-height: 205px;
$-credit-arrow-image: '../../public/images/icon-sprite.png';
$-credit-arrow: (232px, 187px, -232px, -187px, 18px, 18px, 266px, 205px, '../../public/images/icon-sprite.png', '_credit_arrow', );
$arrow-plain-white-name: 'arrow-plain-white';
$arrow-plain-white-x: 232px;
$arrow-plain-white-y: 44px;
$arrow-plain-white-offset-x: -232px;
$arrow-plain-white-offset-y: -44px;
$arrow-plain-white-width: 29px;
$arrow-plain-white-height: 29px;
$arrow-plain-white-total-width: 266px;
$arrow-plain-white-total-height: 205px;
$arrow-plain-white-image: '../../public/images/icon-sprite.png';
$arrow-plain-white: (232px, 44px, -232px, -44px, 29px, 29px, 266px, 205px, '../../public/images/icon-sprite.png', 'arrow-plain-white', );
$block-gallery-white-name: 'block-gallery-white';
$block-gallery-white-x: 65px;
$block-gallery-white-y: 0px;
$block-gallery-white-offset-x: -65px;
$block-gallery-white-offset-y: 0px;
$block-gallery-white-width: 65px;
$block-gallery-white-height: 54px;
$block-gallery-white-total-width: 266px;
$block-gallery-white-total-height: 205px;
$block-gallery-white-image: '../../public/images/icon-sprite.png';
$block-gallery-white: (65px, 0px, -65px, 0px, 65px, 54px, 266px, 205px, '../../public/images/icon-sprite.png', 'block-gallery-white', );
$facebook-white-name: 'facebook-white';
$facebook-white-x: 180px;
$facebook-white-y: 44px;
$facebook-white-offset-x: -180px;
$facebook-white-offset-y: -44px;
$facebook-white-width: 32px;
$facebook-white-height: 64px;
$facebook-white-total-width: 266px;
$facebook-white-total-height: 205px;
$facebook-white-image: '../../public/images/icon-sprite.png';
$facebook-white: (180px, 44px, -180px, -44px, 32px, 64px, 266px, 205px, '../../public/images/icon-sprite.png', 'facebook-white', );
$find-ico-small-name: 'find-ico-small';
$find-ico-small-x: 232px;
$find-ico-small-y: 159px;
$find-ico-small-offset-x: -232px;
$find-ico-small-offset-y: -159px;
$find-ico-small-width: 22px;
$find-ico-small-height: 28px;
$find-ico-small-total-width: 266px;
$find-ico-small-total-height: 205px;
$find-ico-small-image: '../../public/images/icon-sprite.png';
$find-ico-small: (232px, 159px, -232px, -159px, 22px, 28px, 266px, 205px, '../../public/images/icon-sprite.png', 'find-ico-small', );
$find-ico-name: 'find-ico';
$find-ico-x: 232px;
$find-ico-y: 0px;
$find-ico-offset-x: -232px;
$find-ico-offset-y: 0px;
$find-ico-width: 34px;
$find-ico-height: 44px;
$find-ico-total-width: 266px;
$find-ico-total-height: 205px;
$find-ico-image: '../../public/images/icon-sprite.png';
$find-ico: (232px, 0px, -232px, 0px, 34px, 44px, 266px, 205px, '../../public/images/icon-sprite.png', 'find-ico', );
$gallery-ico-white-name: 'gallery-ico-white';
$gallery-ico-white-x: 41px;
$gallery-ico-white-y: 164px;
$gallery-ico-white-offset-x: -41px;
$gallery-ico-white-offset-y: -164px;
$gallery-ico-white-width: 52px;
$gallery-ico-white-height: 32px;
$gallery-ico-white-total-width: 266px;
$gallery-ico-white-total-height: 205px;
$gallery-ico-white-image: '../../public/images/icon-sprite.png';
$gallery-ico-white: (41px, 164px, -41px, -164px, 52px, 32px, 266px, 205px, '../../public/images/icon-sprite.png', 'gallery-ico-white', );
$gallery-white-name: 'gallery-white';
$gallery-white-x: 0px;
$gallery-white-y: 0px;
$gallery-white-offset-x: 0px;
$gallery-white-offset-y: 0px;
$gallery-white-width: 65px;
$gallery-white-height: 64px;
$gallery-white-total-width: 266px;
$gallery-white-total-height: 205px;
$gallery-white-image: '../../public/images/icon-sprite.png';
$gallery-white: (0px, 0px, 0px, 0px, 65px, 64px, 266px, 205px, '../../public/images/icon-sprite.png', 'gallery-white', );
$infowindow-dark-name: 'infowindow-dark';
$infowindow-dark-x: 215px;
$infowindow-dark-y: 149px;
$infowindow-dark-offset-x: -215px;
$infowindow-dark-offset-y: -149px;
$infowindow-dark-width: 17px;
$infowindow-dark-height: 9px;
$infowindow-dark-total-width: 266px;
$infowindow-dark-total-height: 205px;
$infowindow-dark-image: '../../public/images/icon-sprite.png';
$infowindow-dark: (215px, 149px, -215px, -149px, 17px, 9px, 266px, 205px, '../../public/images/icon-sprite.png', 'infowindow-dark', );
$infowindow-light-name: 'infowindow-light';
$infowindow-light-x: 130px;
$infowindow-light-y: 100px;
$infowindow-light-offset-x: -130px;
$infowindow-light-offset-y: -100px;
$infowindow-light-width: 17px;
$infowindow-light-height: 9px;
$infowindow-light-total-width: 266px;
$infowindow-light-total-height: 205px;
$infowindow-light-image: '../../public/images/icon-sprite.png';
$infowindow-light: (130px, 100px, -130px, -100px, 17px, 9px, 266px, 205px, '../../public/images/icon-sprite.png', 'infowindow-light', );
$li-bullet-name: 'li-bullet';
$li-bullet-x: 250px;
$li-bullet-y: 187px;
$li-bullet-offset-x: -250px;
$li-bullet-offset-y: -187px;
$li-bullet-width: 14px;
$li-bullet-height: 14px;
$li-bullet-total-width: 266px;
$li-bullet-total-height: 205px;
$li-bullet-image: '../../public/images/icon-sprite.png';
$li-bullet: (250px, 187px, -250px, -187px, 14px, 14px, 266px, 205px, '../../public/images/icon-sprite.png', 'li-bullet', );
$mail-white-name: 'mail-white';
$mail-white-x: 59px;
$mail-white-y: 64px;
$mail-white-offset-x: -59px;
$mail-white-offset-y: -64px;
$mail-white-width: 57px;
$mail-white-height: 45px;
$mail-white-total-width: 266px;
$mail-white-total-height: 205px;
$mail-white-image: '../../public/images/icon-sprite.png';
$mail-white: (59px, 64px, -59px, -64px, 57px, 45px, 266px, 205px, '../../public/images/icon-sprite.png', 'mail-white', );
$pin-blue-name: 'pin-blue';
$pin-blue-x: 232px;
$pin-blue-y: 102px;
$pin-blue-offset-x: -232px;
$pin-blue-offset-y: -102px;
$pin-blue-width: 24px;
$pin-blue-height: 32px;
$pin-blue-total-width: 266px;
$pin-blue-total-height: 205px;
$pin-blue-image: '../../public/images/icon-sprite.png';
$pin-blue: (232px, 102px, -232px, -102px, 24px, 32px, 266px, 205px, '../../public/images/icon-sprite.png', 'pin-blue', );
$scrolltop-white-name: 'scrolltop-white';
$scrolltop-white-x: 180px;
$scrolltop-white-y: 149px;
$scrolltop-white-offset-x: -180px;
$scrolltop-white-offset-y: -149px;
$scrolltop-white-width: 18px;
$scrolltop-white-height: 12px;
$scrolltop-white-total-width: 266px;
$scrolltop-white-total-height: 205px;
$scrolltop-white-image: '../../public/images/icon-sprite.png';
$scrolltop-white: (180px, 149px, -180px, -149px, 18px, 12px, 266px, 205px, '../../public/images/icon-sprite.png', 'scrolltop-white', );
$slider-arrow-next-name: 'slider-arrow-next';
$slider-arrow-next-x: 180px;
$slider-arrow-next-y: 108px;
$slider-arrow-next-offset-x: -180px;
$slider-arrow-next-offset-y: -108px;
$slider-arrow-next-width: 41px;
$slider-arrow-next-height: 41px;
$slider-arrow-next-total-width: 266px;
$slider-arrow-next-total-height: 205px;
$slider-arrow-next-image: '../../public/images/icon-sprite.png';
$slider-arrow-next: (180px, 108px, -180px, -108px, 41px, 41px, 266px, 205px, '../../public/images/icon-sprite.png', 'slider-arrow-next', );
$slider-arrow-prev-name: 'slider-arrow-prev';
$slider-arrow-prev-x: 0px;
$slider-arrow-prev-y: 164px;
$slider-arrow-prev-offset-x: 0px;
$slider-arrow-prev-offset-y: -164px;
$slider-arrow-prev-width: 41px;
$slider-arrow-prev-height: 41px;
$slider-arrow-prev-total-width: 266px;
$slider-arrow-prev-total-height: 205px;
$slider-arrow-prev-image: '../../public/images/icon-sprite.png';
$slider-arrow-prev: (0px, 164px, 0px, -164px, 41px, 41px, 266px, 205px, '../../public/images/icon-sprite.png', 'slider-arrow-prev', );
$social-blainville-white-name: 'social-blainville-white';
$social-blainville-white-x: 130px;
$social-blainville-white-y: 50px;
$social-blainville-white-offset-x: -130px;
$social-blainville-white-offset-y: -50px;
$social-blainville-white-width: 50px;
$social-blainville-white-height: 50px;
$social-blainville-white-total-width: 266px;
$social-blainville-white-total-height: 205px;
$social-blainville-white-image: '../../public/images/icon-sprite.png';
$social-blainville-white: (130px, 50px, -130px, -50px, 50px, 50px, 266px, 205px, '../../public/images/icon-sprite.png', 'social-blainville-white', );
$social-fb-white-name: 'social-fb-white';
$social-fb-white-x: 0px;
$social-fb-white-y: 114px;
$social-fb-white-offset-x: 0px;
$social-fb-white-offset-y: -114px;
$social-fb-white-width: 50px;
$social-fb-white-height: 50px;
$social-fb-white-total-width: 266px;
$social-fb-white-total-height: 205px;
$social-fb-white-image: '../../public/images/icon-sprite.png';
$social-fb-white: (0px, 114px, 0px, -114px, 50px, 50px, 266px, 205px, '../../public/images/icon-sprite.png', 'social-fb-white', );
$social-mail-white-name: 'social-mail-white';
$social-mail-white-x: 50px;
$social-mail-white-y: 114px;
$social-mail-white-offset-x: -50px;
$social-mail-white-offset-y: -114px;
$social-mail-white-width: 50px;
$social-mail-white-height: 50px;
$social-mail-white-total-width: 266px;
$social-mail-white-total-height: 205px;
$social-mail-white-image: '../../public/images/icon-sprite.png';
$social-mail-white: (50px, 114px, -50px, -114px, 50px, 50px, 266px, 205px, '../../public/images/icon-sprite.png', 'social-mail-white', );
$social-twitter-white-name: 'social-twitter-white';
$social-twitter-white-x: 130px;
$social-twitter-white-y: 0px;
$social-twitter-white-offset-x: -130px;
$social-twitter-white-offset-y: 0px;
$social-twitter-white-width: 50px;
$social-twitter-white-height: 50px;
$social-twitter-white-total-width: 266px;
$social-twitter-white-total-height: 205px;
$social-twitter-white-image: '../../public/images/icon-sprite.png';
$social-twitter-white: (130px, 0px, -130px, 0px, 50px, 50px, 266px, 205px, '../../public/images/icon-sprite.png', 'social-twitter-white', );
$toggle-down-name: 'toggle-down';
$toggle-down-x: 212px;
$toggle-down-y: 83px;
$toggle-down-offset-x: -212px;
$toggle-down-offset-y: -83px;
$toggle-down-width: 19px;
$toggle-down-height: 19px;
$toggle-down-total-width: 266px;
$toggle-down-total-height: 205px;
$toggle-down-image: '../../public/images/icon-sprite.png';
$toggle-down: (212px, 83px, -212px, -83px, 19px, 19px, 266px, 205px, '../../public/images/icon-sprite.png', 'toggle-down', );
$toggle-left-name: 'toggle-left';
$toggle-left-x: 232px;
$toggle-left-y: 134px;
$toggle-left-offset-x: -232px;
$toggle-left-offset-y: -134px;
$toggle-left-width: 25px;
$toggle-left-height: 25px;
$toggle-left-total-width: 266px;
$toggle-left-total-height: 205px;
$toggle-left-image: '../../public/images/icon-sprite.png';
$toggle-left: (232px, 134px, -232px, -134px, 25px, 25px, 266px, 205px, '../../public/images/icon-sprite.png', 'toggle-left', );
$toggle-right-white-name: 'toggle-right-white';
$toggle-right-white-x: 212px;
$toggle-right-white-y: 44px;
$toggle-right-white-offset-x: -212px;
$toggle-right-white-offset-y: -44px;
$toggle-right-white-width: 20px;
$toggle-right-white-height: 20px;
$toggle-right-white-total-width: 266px;
$toggle-right-white-total-height: 205px;
$toggle-right-white-image: '../../public/images/icon-sprite.png';
$toggle-right-white: (212px, 44px, -212px, -44px, 20px, 20px, 266px, 205px, '../../public/images/icon-sprite.png', 'toggle-right-white', );
$toggle-right-name: 'toggle-right';
$toggle-right-x: 232px;
$toggle-right-y: 73px;
$toggle-right-offset-x: -232px;
$toggle-right-offset-y: -73px;
$toggle-right-width: 29px;
$toggle-right-height: 29px;
$toggle-right-total-width: 266px;
$toggle-right-total-height: 205px;
$toggle-right-image: '../../public/images/icon-sprite.png';
$toggle-right: (232px, 73px, -232px, -73px, 29px, 29px, 266px, 205px, '../../public/images/icon-sprite.png', 'toggle-right', );
$toggle-up-name: 'toggle-up';
$toggle-up-x: 212px;
$toggle-up-y: 64px;
$toggle-up-offset-x: -212px;
$toggle-up-offset-y: -64px;
$toggle-up-width: 19px;
$toggle-up-height: 19px;
$toggle-up-total-width: 266px;
$toggle-up-total-height: 205px;
$toggle-up-image: '../../public/images/icon-sprite.png';
$toggle-up: (212px, 64px, -212px, -64px, 19px, 19px, 266px, 205px, '../../public/images/icon-sprite.png', 'toggle-up', );
$twitter-white-name: 'twitter-white';
$twitter-white-x: 0px;
$twitter-white-y: 64px;
$twitter-white-offset-x: 0px;
$twitter-white-offset-y: -64px;
$twitter-white-width: 59px;
$twitter-white-height: 50px;
$twitter-white-total-width: 266px;
$twitter-white-total-height: 205px;
$twitter-white-image: '../../public/images/icon-sprite.png';
$twitter-white: (0px, 64px, 0px, -64px, 59px, 50px, 266px, 205px, '../../public/images/icon-sprite.png', 'twitter-white', );
$ul-dropdown-white-name: 'ul-dropdown-white';
$ul-dropdown-white-x: 198px;
$ul-dropdown-white-y: 149px;
$ul-dropdown-white-offset-x: -198px;
$ul-dropdown-white-offset-y: -149px;
$ul-dropdown-white-width: 17px;
$ul-dropdown-white-height: 11px;
$ul-dropdown-white-total-width: 266px;
$ul-dropdown-white-total-height: 205px;
$ul-dropdown-white-image: '../../public/images/icon-sprite.png';
$ul-dropdown-white: (198px, 149px, -198px, -149px, 17px, 11px, 266px, 205px, '../../public/images/icon-sprite.png', 'ul-dropdown-white', );
$video-ico-white-name: 'video-ico-white';
$video-ico-white-x: 100px;
$video-ico-white-y: 114px;
$video-ico-white-offset-x: -100px;
$video-ico-white-offset-y: -114px;
$video-ico-white-width: 52px;
$video-ico-white-height: 44px;
$video-ico-white-total-width: 266px;
$video-ico-white-total-height: 205px;
$video-ico-white-image: '../../public/images/icon-sprite.png';
$video-ico-white: (100px, 114px, -100px, -114px, 52px, 44px, 266px, 205px, '../../public/images/icon-sprite.png', 'video-ico-white', );
$visit-ico-white-name: 'visit-ico-white';
$visit-ico-white-x: 180px;
$visit-ico-white-y: 0px;
$visit-ico-white-offset-x: -180px;
$visit-ico-white-offset-y: 0px;
$visit-ico-white-width: 52px;
$visit-ico-white-height: 44px;
$visit-ico-white-total-width: 266px;
$visit-ico-white-total-height: 205px;
$visit-ico-white-image: '../../public/images/icon-sprite.png';
$visit-ico-white: (180px, 0px, -180px, 0px, 52px, 44px, 266px, 205px, '../../public/images/icon-sprite.png', 'visit-ico-white', );
$spritesheet-width: 266px;
$spritesheet-height: 205px;
$spritesheet-image: '../../public/images/icon-sprite.png';
$spritesheet-sprites: ($-credit-arrow, $arrow-plain-white, $block-gallery-white, $facebook-white, $find-ico-small, $find-ico, $gallery-ico-white, $gallery-white, $infowindow-dark, $infowindow-light, $li-bullet, $mail-white, $pin-blue, $scrolltop-white, $slider-arrow-next, $slider-arrow-prev, $social-blainville-white, $social-fb-white, $social-mail-white, $social-twitter-white, $toggle-down, $toggle-left, $toggle-right-white, $toggle-right, $toggle-up, $twitter-white, $ul-dropdown-white, $video-ico-white, $visit-ico-white, );
$spritesheet: (266px, 205px, '../../public/images/icon-sprite.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
