.c-block_avantage{
    padding:80px 0 90px;
    background:url('#{$image-dir}/inner-produit-avantage-bg.svg')no-repeat top center/cover;

    .body {
        @include calc-rem($base-font-context);
    
        text-align: left;

        h2{
            text-align: center;
            color: scheme-color('primary','light');
            margin-bottom: 30px;
        }

        .desc{
            
            ul{
                li{
                    position: relative;
                    display: inline-block;
                    width: span(8);
                    margin-bottom: 15px;
                    list-style: none;
                    color: scheme-color('primary','light');
                    &:before{
                        width: 8px;
                        height: 8px;
                        background: $global-background-yellow;
                        border-radius: 50%;
                        display: inline-block;
                        content: "";
                        margin-right: 10px;
                    }
                    @include breakpoints($xs-only,$sm-only){
                        width: span(24);
                    }
                }
            }
        }

        @extend .animated;
        @extend .fadeInLeft;
        @include breakpoints($xs-only,$sm-only){
          width: span(24);
          display: table-footer-group;
          text-align: center;
          ul{
                  text-align: left;
                  display: inline-block;
          }
        }
    }
}