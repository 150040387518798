.c-navigations{
  width:span(16);
  text-align: right;
  //float:right;
  display:inline-block;
  //padding-top:45px;
  
  @include breakpoints($md-only){
    width:span(17);
  }
  @include breakpoints($xs-only,$sm-only){
    display:none;
  }
  .navigation_top{
    //position:absolute;
    // top:0;
    // left: 0;
    // width:100%;
    padding-top: 20px;
    text-align: right;
    font-family: $font-title;
    @include calc-rem(14);
    // &__wrapper{
    //     @extend %layout;
    // }
    .share{

      display:inline-block;
      ul{

        margin:0;

        li{
          display:inline-block;
          margin-right:5px;
          a{
            &:link,&:visited{
              color:$global-color;

            }
            &:hover,&:focus{
              color:$global-emphasis-color;
            }
          }

          &.last-social{
            margin-right:25px;
          }
        }

      }
    }
    .phone{
      display:inline-block;
      padding-right:15px;
      strong{
        color:$global-emphasis-color;
        font-weight: $bold;
        @include calc-rem(24);
        padding-left: 8px;
      }
    }
    .lang_selector{
      //display: none;
      a{
        color:#225687;
        text-transform: capitalize;
        font-weight: $bold;
        font-size: inherit;
        &:hover{
          color:$global-color;
        }
      }
      &:before{
        content : "|";
        margin-right: 15px;
        display: none;
      }
    }
    .top_button{
      background: $global-background-blue;
      color: $global-white;
      padding: 12px 55px;
      display: inline-block;
      font-weight: $bold;
      text-transform: uppercase;
      margin-left: 25px;
      &:hover{
        background: $global-background-blue-hover;
      }
    }
  }
  .navigation_bottom{
    ul{
      padding-left:0;
      margin:0;
      list-style: none;
      li{
        display:inline-block;
        margin-right:5px;
        position: relative;
        margin-right:20px;
        @include breakpoints($md-only){
          margin-right:15px;
        }
        a{
          font-family: $font-title;
          line-height: 70px;
          display: inline-block;
          font-weight: $bold;
          &:link,&:visited{
            color:$global-color;
          }
          &:after{
            content:"";
            background:scheme-color('primary', 'yellow');
            height: 3px;
            width: 100%;
            position: absolute;
            left: 0;
            bottom:0;
            opacity: 0;
            transition: opacity .3s ease;
          }
        }
        &:hover, &.current_page_item, &.current-menu-parent{
          a:after{
            opacity: 1;
          }
        }
        &.menu-item-has-children:hover{
          .sub-menu{
            display: block;
          }
        }
        &:last-child{
          margin-right:0;
        }
        &.menu-item-has-children{
          &:after {
            content: "\f078";
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;

            background-repeat: no-repeat;
            margin-left:5px;
            color:scheme-color('primary', 'yellow');
            @include calc-rem(15);
            vertical-align: middle;
            margin-top: -4px;
            /*position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);*/
          }
        }
        // &.current-menu-item a, &:hover a, &.current_page_parent > a {
        //   color:$global-emphasis-color;
        //
        //
        // }
        &:hover{
          ul{
            display:block;
          }
        }
        ul{
          display:none;
          position:absolute;
          top:100%;
          left:-20px;
          min-width: 260px;
          width: 100%;
          padding: 0;
          margin: 0;
          text-align:left;
          background:scheme-color('primary', 'base');
          //box-shadow: 0 25px 40px -20px rgba(#000, 0.2);


          li {
            float: none;
            padding: 0;
            width: 100%;
            margin:0;
            @include calc-rem(15);
            &:hover a {
              color: scheme-color('primary', 'light');
              //background:scheme-color('primary', 'light');
            }

            a {

              width: 100%;
              display: inline-block;
              padding: 10px 20px;
              background:scheme-color('primary', 'base');
              line-height: 30px;
              transition: color 0.2s ease;
              color:scheme-color('primary', 'light');
              cursor: pointer;
              transition: all ease 0.5s;
              &:link,&:visited{
                color:scheme-color('primary', 'light');
              }
              &:hover,&:focus{
                background: $global-background-blue-hover;
              }
              &:before, &:after {
                display: none;
              }
            }

          }
        }
      }

    }

  }

}
