@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-Italic.eot');
    src: url('#{$fonts-dir}/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-Italic.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-Italic.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-Italic.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-BoldItalic.eot');
    src: url('#{$fonts-dir}/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-BoldItalic.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-BoldItalic.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-BoldItalic.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-Semibold.eot');
    src: url('#{$fonts-dir}/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-Semibold.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-Semibold.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-Semibold.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-Semibold.svg#OpenSans-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-Light.eot');
    src: url('#{$fonts-dir}/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-Light.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-Light.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-Light.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-Light.svg#OpenSans-Light') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-Extrabold.eot');
    src: url('#{$fonts-dir}/OpenSans-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-Extrabold.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-Extrabold.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-Extrabold.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-Extrabold.svg#OpenSans-Extrabold') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-ExtraboldItalic.eot');
    src: url('#{$fonts-dir}/OpenSans-ExtraboldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-ExtraboldItalic.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-ExtraboldItalic.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-ExtraboldItalic.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-ExtraboldItalic.svg#OpenSans-ExtraboldItalic') format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-SemiboldItalic.eot');
    src: url('#{$fonts-dir}/OpenSans-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-SemiboldItalic.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-SemiboldItalic.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-SemiboldItalic.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-SemiboldItalic.svg#OpenSans-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans-Bold.eot');
    src: url('#{$fonts-dir}/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans-Bold.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans-Bold.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans-Bold.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSans.eot');
    src: url('#{$fonts-dir}/OpenSans.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSans.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSans.woff') format('woff'),
        url('#{$fonts-dir}/OpenSans.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSans.svg#OpenSans') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('#{$fonts-dir}/OpenSansLight-Italic.eot');
    src: url('#{$fonts-dir}/OpenSansLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$fonts-dir}/OpenSansLight-Italic.woff2') format('woff2'),
        url('#{$fonts-dir}/OpenSansLight-Italic.woff') format('woff'),
        url('#{$fonts-dir}/OpenSansLight-Italic.ttf') format('truetype'),
        url('#{$fonts-dir}/OpenSansLight-Italic.svg#OpenSansLight-Italic') format('svg');
    font-weight: 300;
    font-style: italic;
}
