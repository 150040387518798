.c-block-_3col{
  background-size: cover;
  //background-position: center center;
  background-repeat: no-repeat;
  @extend %block-layout;
  padding-top:90px;
  padding-bottom:50px;
  .title{
    text-align: center;
    .prev,.next{
      display:inline-block;
      a{
        @include calc-rem(50);
        &:link,&:visited{
          color: $global-emphasis-color;
        }

        &:hover,&:focus{
          color:$global-color;
        }

        @include breakpoints($xs-only,$sm-only){
          @include calc-rem(30);
        }
      }
    }
    #{headings(1,6)} {
      font-family: $font-title;
      font-weight:$medium;
      display:inline-block;
      padding:0 5%;
      @include breakpoints($xs-only,$sm-only){
        padding:0 5px;
      }
    }
  }
  .description{
    @include calc-rem(18);
    text-align: center;
    margin-bottom: 80px;
    margin-top: 60px;
    p{
      margin: 0;
    }
  }
  .items{
    @include calc-rem($base-font-context);
    .back-link{
      clear: both;
      display: block;
      text-align: center;

      a{
        i{
          margin-right:15px;
        }
        &:link,&:visited{
          color:$global-color;
        }
        &:hover,&:focus{
          color: $global-emphasis-color;
        }
      }
    }
  }
  .item {

    position: relative;
    //color: scheme-color('primary', 'light');
    text-align: center;
    @include gallery(8 of 24);
    margin-bottom: gutter();
    padding:35px 25px 80px;

    /*@include breakpoints($md-only){
    @include gallery(12 of 24);

    &:last-child{
    display:none;
  }
}*/


.media{
  margin-bottom:40px;
  img{
    max-width:100%;
    height:auto;
  }
}

.body {
  width: 100%;
  p{
    margin:0;
  }


}
.c-btn{
  margin-top:50px;
}
#{headings(1,6)} {
  font-family: $font-title;
  font-weight:$medium;
  @include calc-rem(30);
  text-transform: none;

  &.no-content{
    @include calc-rem(50);

  }

  small{
    display:block;
    font-family: $font-regular;
    font-weight: $bold;
    @include calc-rem(20);
    margin-top:5px;
  }
}

@include breakpoints($xs-only,$sm-only){
  width:span(24);
  margin-left:0!important;
  margin-right:0!important;
  margin-bottom:20px;
  padding-bottom:0;
  text-align: center;
  #{headings(1,6)} {
    @include calc-rem(20);
    &.no-content{
      @include calc-rem(30);
    }
    small{
      @include calc-rem(18);
    }
  }
  &.bigtitle {
    #{headings(1,6)} {
      @include calc-rem(30);
      &.no-content{
        @include calc-rem(30);
      }
      small{
        @include calc-rem(20);
      }
    }
  }
}
}
&.bigtitle {
  .item{
    #{headings(1,6)} {
      @include calc-rem(42);

      &.no-content{
        @include calc-rem(50);
      }
      small{
        @include calc-rem(30);
      }
    }

    @include breakpoints($xs-only,$sm-only){
      #{headings(1,6)} {
        @include calc-rem(30);
        &.no-content{
          @include calc-rem(30);
        }
        small{
          @include calc-rem(20);
        }
      }
    }
  }
}
}
