 .c-footercta{
   background-color: $global-emphasis-color;
   @include calc-rem(24);
   text-align: center;
   padding: 100px 0;
   color:$global-inverse-color;
   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
       overflow: hidden;
   @include breakpoints($xs-only,$sm-only){
 		@include calc-rem(20);
 	 }
   &_title{
     font-weight:$bold;
     color:$global-inverse-color;
   }
   &_description{
    color: $global-color;
    margin: 55px 0;
   }
   &__wrapper{
     width: span(18);
     margin:auto;
     @extend .animated;
     @extend .fadeInDown;
     @include breakpoints($xs-only,$sm-only){
   		width: span(22);
   	 }
   }
 }
