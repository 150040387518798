.c-block{
    width:span(24);
    //box-shadow: inset 0px 10px 20px -7px $shadow;
    @extend .animated;
    @extend .fadeIn;
    background-color:$global-background;

    &__wrapper{
        font-size: 0;
        @extend %layout;
        margin:0 auto;

    }
    .item{
        @include calc-rem($base-font-context);
        &.center{
            text-align:center;
        }
        &.left{
            text-align:left;
            @include breakpoints($xs-only,$sm-only){
                text-align: center;
                .body{
                    text-align: center;

                }
            }
        }
        &.right{
            text-align:right;
        }
        &.justify{
            text-align:justify;
        }
    }
    .title{
        @include calc-rem($base-font-context);
    }
    .body{
        @include calc-rem($base-font-context);
    }
    .c-btn{
        margin-top:40px;
    }
}
