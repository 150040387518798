.c-gallery{
  padding: 80px 0;
  &_wrapper{
    @extend %layout;
  }
  &_grid{
    display: flex;
    justify-content: space-around;
    @include breakpoints($xs-only, $sm-only) {
      display: block;
      text-align: center;
    }
  }
  &_item{
    @extend .animated;
    @extend .fadeIn;
  }
  img{
    max-width: 100%;
  }
  // &.grey{
  //   background:scheme-color('primary','grey');
  // }
}
