.c-block-_std{
  @extend %block-layout;
  padding:90px 0;
  background-size:cover;
  background-position: center center;
  &.empty-image{
    //text-align: center;
    padding:80px 0 68px;
    .body{
      width: span(20);
      max-width:1000px;
      //margin:0 auto;
      //text-align: center;
      color:$global-color;
      @include breakpoints($xs-only,$sm-only){
        width: span(24);
      }
    }
    @include breakpoints($xs-only,$sm-only){
      padding-top: 30px;
    }
  }
  &#presence-sur-tout-le-territoire{
    padding-top: 600px;
    background-size: auto 80%;
    background-position: 50% -40%;
    .body{
      margin: 0 auto;
      text-align: center;
    }
    @include breakpoints($sm-only){
      padding-top: 100px;
      background-size: auto 55%;
      background-position: 50% -30%;
    }
    @include breakpoints($xs-only){
      padding-top: 100px;
      background-size: auto 35%;
      background-position: 50% -10%;
    }
  }
  .c-block__wrapper{
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h2{
      color: #002D62;
      small{
        margin-top: 35px;
      }
    }

    @include breakpoints($xs-only,$sm-only){
      flex-direction: column;
    }
  }

  .media {
    width: span(11);
    margin-right:span(1);
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    order: -1;
    @extend .animated;
    @extend .fadeInRight;
    @include breakpoints($xs-only,$sm-only){
      width: span(24);
      padding-right:0;
      margin:0;
      display: table-header-group;
      text-align: center;
      margin-bottom:20px;
      float: none;
      background: none !important;
      img {
        margin-bottom:20px;
      }
    }
    img {
      max-width: 400px;
      max-height: 500px;
      height: auto;
      width: 100%;
    }
  }

  .body {
    @include calc-rem($base-font-context);
    width: span(13);
    display: inline-block;
    vertical-align: middle;

    text-align: left;
    @extend .animated;
    @extend .fadeInLeft;
    @include breakpoints($xs-only,$sm-only){
      width: span(24);
      display: table-footer-group;
      text-align: center;
      ul{
      		text-align: left;
      		display: inline-block;
      }
    }
    &_text{
      margin-top:40px;
      color: #002D62;
      strong{
        font-size: 28px;
        font-weight: normal;
      }
      &_emphasis{
        border-left: $global-border solid $global-border-width;
        padding-left: $global-quote-margin;
        + .c-btn{
          margin-left: $global-quote-margin + $global-border-width;
        }
        @include breakpoints($xs-only,$sm-only){
      		border:none;
      		padding-left: 0;
      		+ .c-btn{
      			margin-left: 0;
      		}
      	}
      }
    }
    h1 + h2{
      font-weight: normal;
      display: block;
      @include calc-rem(28);
    }
  }
  &.media_bottom,
  &.media_top {
    .c-block__wrapper{
      flex-direction: column;
    }
    .media{
      width: 100%;
      text-align: center;
      margin-right: 0;
      margin-bottom: 0;
      display: block;
    }
    .body{
      text-align: center;
      width: 100%;
    }
  }
  &.media_bottom {
    // padding-bottom: 0;
    text-align: center;
    .media{
      margin-top: 50px;
      order: 99;
      img{
        margin: 0;
      }
    }
    .body_text{
      margin-top: 0;
    }
  }
  &.media_top {
    .media{
      margin-bottom: 50px;
    }
  }
   &.media_right {
    .media{
      order: 1;
      margin-left:span(1);
      margin-right: 0;
      @include breakpoints($xs-only,$sm-only){
        order: -1;
      }
    }
    
  }
  &.media_left {
    .media{
      order: -1;
    }
  }
}

.produit-template-default .c-block-_std{
  .c-block__wrapper{
    flex-direction: row-reverse;
    align-items: flex-start;
    @include breakpoints($xs-only,$sm-only){
      flex-direction: column;
    }
  }
  .media{
    background: url('#{$image-dir}/inner-produit-bg.svg')no-repeat;
    background-position: 70% -100px;
    background-size: 120%;
    min-height: 600px;
    text-align: center;
    order: -1;
    @include breakpoints($xs-only,$sm-only){
      min-height: auto;
    }
  }
}