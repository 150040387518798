$button-background : $global-background-yellow;
$button-color : $global-white;
$button-border : $global-background-yellow;

$button-hover-background : $global-background-yellow-hover;
$button-hover-color : $global-white;
$button-hover-border : $global-background-yellow;

$button-primary-background : $global-background-yellow;
$button-primary-color : $global-white;
$button-primary-border : $global-background-yellow;;

$button-primary-hover-background : $global-background-yellow-hover;
$button-primary-hover-color : $global-white;
$button-primary-hover-border : $global-background-yellow;

$button-inverse-background:transparent;
$button-inverse-border:$global-inverse-color;
$button-inverse-color:$global-inverse-color;

$button-inverse-hover-background:$button-inverse-border;
$button-inverse-hover-border:$global-inverse-color;
$button-inverse-hover-color:$global-emphasis-color;

$button-inverse-primary-background:$global-background-yellow;
$button-inverse-primary-border:$global-background-yellow;
$button-inverse-primary-color:$global-white;

$button-inverse-primary-hover-background:$global-background-yellow-hover;
$button-inverse-primary-hover-border:$global-background-yellow;
$button-inverse-primary-hover-color:$global-white;

.c-btn {

    &:link,&:visited{
        display: inline-block;
        @include calc-rem(22);
        outline: none;
        padding: 15px 60px;
        position: relative;
        text-decoration: none;
        background: $button-background;
        border: 1px solid $button-border;
        color:$button-color;
        text-transform: uppercase;
        font-weight: $bold;
        @include breakpoints($xs-only,$sm-only){
          @include calc-rem(15);
          padding: 12px 30px;
        }
    }


    &:hover,&:focus {
        cursor: pointer;
        /*@extend .animated;
        @extend .infinite;
        @extend .pulse;*/
        background: $button-hover-background;
        //border: 3px solid scheme-color('primary', 'base-btn-hover');
        color:$button-hover-color;

    }

    &--revert{
        &:link,&:visited {
            background: $button-inverse-background;
            border-color: $button-inverse-border;
            color: $button-inverse-color;
        }
        &:hover {
            background:$button-inverse-hover-background;
            border-color: $button-inverse-hover-border;
            color:$button-inverse-hover-color;
        }
    }

    &.disabled{
        opacity:0.5;
        cursor: no-drop;
    }
    &-primary{
      &:link,&:visited{
        background-color: $button-primary-background;
        border-color:$button-primary-border;
        color:$button-primary-color;
      }
      &:hover {
        background-color: $button-primary-hover-background;
        border-color:$button-primary-hover-border;
        color:$button-primary-hover-color;
      }
      &.c-btn--revert{
        &:link,&:visited {
          background: $button-inverse-primary-background;
          border-color: $button-inverse-primary-border;
          color: $button-inverse-primary-color;
        }
        &:hover {
          background:$button-inverse-primary-hover-background;
          border-color: $button-inverse-primary-hover-border;
          color:$button-inverse-primary-hover-color;
        }
      }
    }
}
