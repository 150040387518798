/*------------------------------------------------------------------
[Variables]
-------------------------------------------------------------------*/
$scheme-colors: (
'primary': (
'base': #008FFF,
'support': #EE3424,
'baselight': #48aaf7,
'light': #fff,
'dark': #000,
'grey' : #f2f2f2,
'btn-hover' :transparent,
'base-btn-hover' :#fff,
'yellow' : #FFB518,
'yellow-hover' : #ffc54a,
'blue-light' : #008FFF,
'blue-dark' : #00335B,
)
);

$global-color:#000;
$global-emphasis-color:#008FFF;
$global-white:#fff;

$global-background:#fff;
$global-background-muted:#f2f2f2;
$global-background-blue:#008FFF;
$global-background-blue-hover:#48aaf7;
$global-background-muted:#f2f2f2;
$global-background-blue-dark:#00335B;
$global-background-blue-dark-hover:#48aaf7;
$global-background-muted:#f2f2f2;
$global-background-yellow:#FFB518;
$global-background-yellow-hover:#ffc54a;
$global-background-primary:$global-emphasis-color;
$global-background-secondary:darken(adjust-hue($global-color, 1), 5.69);

$global-border-width:2px;
$global-border:$global-color;

$global-inverse-color:$global-background;

$global-quote-margin:40px;

$shadow :  rgba(0, 0, 0, 0.2);

//Load mixins, functions...
@import "utils/_loader";

// html, heading, etc.
@import "base/_globals";

//_fonts
@import "_fonts.scss";

@import url('https://fonts.googleapis.com/css?family=Barlow:300,400,500,700,900');

//@import "debug.scss";

/*
Lato
Lato Hairline
Radikal
Radikal UltraThin
*/

$font-size-context: 18;
@include breakpoints($xlg-only){
	$font-size-context: 25;
}
$base-font-context: $font-size-context;
$font-title: 'Barlow', "Times New Roman", Times, serif;;
$font-regular: 'Barlow', 'Open Sans', Arial, Helvetica, sans-serif;
$font-base: $font-regular;

$black : 900;
$bold : 700;
$medium : 500;


//_sprites
@import "_sprites";
.sprite {
	display: inline-block;

	@each $sprite in $spritesheet-sprites {
		$sprite-name: nth($sprite, 10);
		&--#{$sprite-name} {
			@include sprite($sprite);
		}
	}
}



/*------------------------------------------------------------------
[Éléments HTML]
-------------------------------------------------------------------*/
:root {
	font-size: $font-size-context * 1px;
}
.clearfix{
	overflow: auto;
	&:after {
    content: "";
    clear: both;
    display: table;
	}
}
.c-align-center{text-align:center;}
.c-align-left{text-align:left;}
.c-align-right{text-align:right;}
.loaded{
	.loading{
		display:none;
	}
}
.c-map-google{
	height: 740px;
	max-height: 100vh;
}
blockquote{
	margin-left: 0;
	border-left: $global-border solid $global-border-width;
	padding-left: $global-quote-margin;
	font-style: italic;
	~ * {
		padding-left: $global-quote-margin;
	}
	@include breakpoints($xs-only,$sm-only){
		border:none;
		padding-left: 0;
		~ * {
			padding-left: 0;
		}
	}
}
.loading{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	/*width: 100px;
	height: 47px;*/

	&:before{
		display:block;
		/*@extend .animated;
		@extend .bounce;
		@extend .infinite;*/

		//animation: fa-spin 0.5s infinite steps(8);

		@extend .animated;
		@extend .flip;
		@extend .infinite;


		/*@extend .animate-flicker;*/

		width: 100px;
		height: 47px;

		content: '';


		z-index: -1;

		background-image:url('#{$image-dir}/loader.png');
		background-size:cover;
	}
}
body {
	margin: 0;
	font-family: $font-base;
	line-height: 1.4;
	-webkit-font-smoothing: antialiased;
	background-color:$global-background;
	color:$global-color;

}
.l-main {
	overflow-x: hidden;
}

#{headings(1,6)} {
	@include calc-rem(48);
	font-family: $font-title;
	font-weight: $bold;
	margin: 0 0 5px 0;
	line-height: 1.4;
	color:$global-color;
	small{
		font-weight: normal;
		display: block;
		@include calc-rem(28);
	}
	@include breakpoints($sm-only){
		@include calc-rem(40);
		line-height: 1.1em;
		small{
			@include calc-rem(22);
		}
	}
	@include breakpoints($xs-only){
		@include calc-rem(36);
		line-height: 1.1em;
		small{
			@include calc-rem(22);
		}
	}
}
.c-heading-primary{
	&:after{
		content: url('#{$image-dir}/heading_primary.svg');
	  display: block;
	}
}
a,button{
	text-decoration: none;
	transition: all 0.5s ease;
}
a{
	&:link,&:visited{
	color: $global-background-secondary;

	}
	&:hover,&:focus{color:$global-emphasis-color;}
}
.wow{
	visibility:hidden;
}


%block-layout{
	&.light{
		color: $global-color;
		#{headings(1,6)}{
			color: $global-color;
		}
	}
	&.dark{
		color: $global-inverse-color;
		#{headings(1,6)}{
			color: $global-inverse-color;

		}
	}
	&.grey{
		color: $global-color;
		background-color: $global-background-muted;
		#{headings(1,6)}{
			color: $global-color;
		}
	}
	&.box{
		.item{
			padding-bottom:15px;
			padding-left: 30px;
			padding-right: 30px;
			&__wrapper{
				background:$global-inverse-color;
				text-align:center;
				//margin-top:90px;
				padding:65px 45px;
				box-shadow: 4px 4px 10px 0px $shadow;


			}
		}

		@include breakpoints($xs-only,$sm-only){
			padding-top: 0;
			.c-block__wrapper{
				max-width:100%;
			}
			.item{
				padding-top: 0;
				padding-left:0;
				padding-right:0;
				&__wrapper{

					padding:65px 20px;

				}

			}
		}

	}
	&.box-case{
		background: scheme-color('primary','grey');

		.title{
			#{headings(1,6)}{
				max-width:1000px;
				@include calc-rem(30);
				font-weight:$bold;
			}
		}
		.items{
			margin-top:50px;
			.item{
				#{headings(1,3)}{
					font-family:$font-regular;
					font-weight: $bold;
					color: scheme-color('primary','support');
					text-transform: uppercase;
				}
				#{headings(4,6)}{
					font-weight: $bold;
					color: $global-color;
					text-transform: none;
					margin-bottom:0;
					margin-top:40px;
				}
				padding-top:0;
				&:first-child{

					background:$global-color;
					color:$global-inverse-color;
					padding:40px;
					box-shadow: 4px 4px 10px 0px $shadow;

				}
				&.media{
					background:none;
					box-shadow:none;
					img{
						max-width:100%;
						height:auto;
					}

					@include breakpoints($xs-only,$sm-only){
						padding:0;
					}
				}
				.date{
					color: scheme-color('primary','support');
					font-weight: $bold;
				}
			}
		}

	}
	&.center{
		text-align:center;
		.body{
			text-align:center;
		}

	}
	&.left{
		text-align:left;
		.body{
			text-align:left;
		}

		@include breakpoints($xs-only,$sm-only){
			text-align: center;
			.body{
				text-align: center;

			}
		}

	}
	&.right{
		text-align:right;
		.body{
			text-align:right;
		}

	}
	&.justify{
		text-align:justify;
		.body{
			text-align:justify;
		}

	}
}


/*------------------------------------------------------------------
[Style]
On se lance dans la personnalisation de chacune des différentes
portions du site!
-------------------------------------------------------------------*/
/*	Components
================================================================== */

$slick-font-path: "../../bower_components/slick-carousel/slick/fonts/";
/*$slick-loader-path: "./";
$slick-loader-path: "../images/";*/
$slick-loader-path: "../../bower_components/slick-carousel/slick/";


@import "../../bower_components/slick-carousel/slick/slick.scss";
@import "../../bower_components/slick-carousel/slick/slick-theme.scss";

// @import "components/_c-nav";
@import "_formidable";
@import "components/_c-header";
@import "components/_c-navigations";
@import "components/_c-slider";
@import "components/_c-btn";
@import "components/_c-block";
@import "components/_c-footer";
@import "components/_c-credit";
@import "components/_c-submission";
@import "components/_c-gallery";
@import "components/_c-form";
@import "components/_c-pagetitle";
@import "components/_c-pagination";
@import "components/_c-info";
@import "components/_c-block/_std";
@import "components/_c-block/_related";
@import "components/_c-block/_3col";
@import "components/_c-block/_2col";
@import "components/_c-block/_partenariat";
@import "components/_c-block/_listdocument";
@import "components/_c-page-contact";
@import "components/_produit_avantage";


/*------------------------------------------------------------------
[Layout]
Placeholder pour le format de la grille. Comme la grille est assez
personnalisée d'un site à l'autre, on le conserve ici.
-------------------------------------------------------------------*/
%layout {
	@include container;

	@include breakpoint($xlg-only) {
		max-width: $container-width-xlg + px;
		padding-left:span(1);
		padding-right:span(1);
	}

	@include breakpoint($lg-only) {
		max-width: $container-width-lg + px;
	}
}

