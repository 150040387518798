.c-credit{
  background-color: $global-background;
  padding:10px 0;
  h3{
    @include calc-rem(12);
    font-weight: normal;
  }
  a{
    &:link,&:visited{
      color: $global-color;
    }
    &:hover,&:focus{
      color: $global-emphasis-color;
    }
  }
  &__wrapper{
    @extend %layout;
  }
}
