.c-header{
  @extend .animated;
  @extend .fadeIn;
  position:relative;
  //position: absolute;
  width: 100%;
  z-index: 1000;
  //background:rgba(#000,0.5);
  //background-image:url('#{$image-dir}/menu-shadow.png');
  //background-repeat:repeat;
  &__wrapper{
    @extend %layout;
  }
  .logo{
    vertical-align: top;
    position:relative;
    display:inline-block;
    width:span(8);
    padding: 25px 0;
    img{
      max-height:80px;
      max-width:100%;
      height:auto;
    }

    @include breakpoint($md-only) {
      width: span(7);
    }

    @include breakpoints($xs-only, $sm-only) {
      line-height: 90px;
      padding: 0;
      width:span(14);
      img{
        height: 60px;
      }
    }
    @include breakpoints($xs-only) {
      width:span(16);
    }

  }
}
.c-mobile-actions{
  display:none;
  //z-index: 999;
  width: span(10);
  text-align:right;
  float:right;
  text-align: right;
  justify-content: flex-end;
  align-items: center;
  height: 90px;
  @include breakpoints($xs-only, $sm-only) {
    display:flex;
  }
  @include breakpoints($xs-only) {
    //padding-top: 0px;
    width: span(8);
    .nav-trigger{
      width: 42px;
      height: 42px;
    }
  }

  .phone{
    display:inline-block;
    color:$global-emphasis-color;
    font-weight: $black;
    padding-left:15px;
    font-family: $font-title;
    @include calc-rem(24);
    vertical-align: middle;
    @include breakpoints($xs-only) {
      display:none;
    }
  }

}
.nav-trigger {


  width: 50px;
  height: 50px;
  position: relative;
  background: transparent;
  border: none;
  padding: 10px;
  margin: 0;
  cursor: pointer;
  display: inlne-block;
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
  &:focus {
    outline: 0;
  }
  &:hover {
    span,
    span:before,
    span:after {

      background:scheme-color('primary','base');
    }
  }
  &:before {
    content: '';
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    background: transparent;
    transform: translate(-50%, -50%);
    transition: all 0.4s ease;
  }

  span {
    display: block;
    position: relative;
    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0;
    }
    &:before {
      top: -8px;
    }
    &:after {
      bottom: -8px;
    }
  }

  span,
  span:before,
  span:after {
    width: 100%;
    height: 4px;
    background:scheme-color('primary','base');
    transition: all 0.4s ease;
  }

  &.is-active {

    &:hover {
      span:before,
      span:after {
        background: #fff;
      }
    }

    &:before {
      opacity: 1;
      width: 50px;
      height: 50px;
      background:scheme-color('primary','base');
    }
    span {
      background: transparent;
      &:before {
        top: 0;
        transform: rotate(225deg);
        background: #fff;
      }
      &:after {
        bottom: 0;
        transform: rotate(-225deg);
        background: #fff;
      }
    }
  }

}
.menu-open{
  overflow:hidden;

  .l-mobile{
    transform: none;
    transition-delay: 0s;
  }
}
.l-mobile{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(100%);
  transition: .5s ease-in-out;
  content: '';
  z-index: 999;
  //transition-delay: .5s;
  background: $global-background-muted;
  //transition: all .5s;

  @include breakpoints($lg-only,$xlg-only){
    // transform: translateX(100%)!important;
    display:none;
  }
  ul{
    padding:0;
    clear:both;

    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    width: 100%;

    @include breakpoints($md-only){
      transform: translate(-50%,-50%);
      top: 50%;
      left: 50%;
      width: 100%;
    }


    li {
      list-style: none;
      font-family: $font-bold;
      text-transform: uppercase;
      //padding-right: 15px;
      //position: relative;

      a {

        border-bottom:1px solid $global-color;
        display: block;
        color: $global-color;
        padding: 10px 8px;
        position:relative;

        text-align: center;
        @include calc-rem(16);
        @include breakpoint($md-only){
          margin: 0 30%;
        }


        &:hover{
          //color:scheme-color('primary', 'support');
          background: $global-color;
          color: $global-inverse-color;
        }
      }
      &.lang_selector{
        a{
          color: $global-emphasis-color;
          &:hover{
            background: $global-color;
            color: $global-inverse-color;
          }
        }
      }
      &.current-menu-item{
        a{
          background: $global-emphasis-color;
          color: $global-inverse-color;
          border-color:$global-emphasis-color;
        }
      }
    }
  }


}

/*.menu-open{
height: 100%;
overflow: hidden;
.l-main{
transform: translate3d(300px,0,0);

&:after{
width: 100%;
height: 100%;
opacity: 1;
-webkit-transition: opacity .5s;
transition: opacity .5s;
position: absolute;
z-index: 99999;
}
}
.l-mobile{
visibility: visible;
transition: all .5s;
transform: translate3d(0,0,0);

&:after{
width: 0;
height: 0;
opacity: 0;
transition: opacity .5s,width .1s .5s,height .1s .5s;
}

}


}*/
