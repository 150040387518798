.c-pagetitle{
  @extend .animated;
  @extend .fadeIn;

  background-size:cover;
  position: relative;
  width:span(24);
  background-color:$global-background-primary;
  background-position: center;
  background-image:url('#{$image-dir}/cleral-default-banner.svg');
  &__wrapper{
    @extend %layout;
    height:440px;
    text-align: center;
    @include breakpoints($sm-only){
      height:300px;
    }
    @include breakpoints($xs-only){
      height:220px;
    }
    & .cmplz-document{
      display: none !important;
    }
  }
  &_nobackground{
    height: 220px;
  }
  .body{
    position:relative;
    display: flex;
    align-items: center;
    //justify-content: center;
    height: 100%;
    color:$global-inverse-color;
    max-width:100%;
    margin:0 auto;
    .title{
      max-width:1000px;
      
      #{headings(1,6)}{
        color:$global-inverse-color;
        @include calc-rem(60);
        &:after{
      		content: '';
          height: 8px;
          background-image:url('#{$image-dir}/heading_banner.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
      	  display: none;
          margin-top: 25px;
      	}
        @include breakpoints($xs-only,$sm-only){
          font-size: 36px;
        }
      }
    }
    p{
      margin:0;
      @include calc-rem(20);
      strong{
        text-transform: uppercase;
        color:$global-inverse-color;
        font-family: $font-title;
        font-weight: $medium;
      }
    }
  }

}
