$debug-mode: false !default;

$fonts-dir: '../fonts' !default;
$image-dir: '../images' !default;

$container-width-xs: 768;
$container-width-sm: 768;
$container-width-md: 960;
$container-width-lg: 1200;
$container-width-xlg: 1600;

$susy: (
	columns: 24,
	container: 90%,
	global-box-sizing: border-box
);

$light: 200 !default;
$regular: 400 !default;
$medium: 500 !default;
$bold: 700 !default;
$black: 800 !default;

$base-font-context: 16 !default;
$base-font-size: $base-font-context * 1px;
$base-line-height: 1.4;

$font-script: serif !default;
$font-light: sans-serif !default;
$font-black: sans-serif !default;
$font-medium: sans-serif !default;
$font-bold: sans-serif !default;

$base-font-family: $font-medium !default;
$base-font-weight: $regular !default;


$scheme-color-key: 'base' !default;
$scheme-colors: (
	'primary': (
		'base': #8e3329,
		'light': #d9534f,
		'dark': #c9302c
	),

	'accent': (
		'base': #d98328,
		'light': #dd8f3d,
		'dark': #c57623
	),

	'secondary': (
		'base': #5a1321,
		'light': #7b1a2d,
		'dark': #51111e
	),

	'foreground': (
		'base': #191919,
		'light': #333333,
		'dark': #111111,
		'darker': #000000
	),

	'background': (
		'base': #e9e9e9,
		'light': #ffffff,
		'dark': #dddddd
	)
) !default;


$scheme-opacity: (
  'light': 0.8,
  'medium': 0.5,
  'dark': 0.2
) !default;
