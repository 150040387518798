html {
	height: 100%;
}

body.error404 {
	height: 100%;
	min-height: 100%;
}

.quatrecentquatre {
	text-align: center;

	h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}

	&__wrapper {
		display: inline-block;
    padding: 100px 25px;
    //text-align: left;
	}
	.c-btn{
		margin-top: 20px;
	}
	// a {
	// 	display: inline-block;
	// 	text-decoration: none;
	// 	margin-top: 18px;
	// 	color: #000;
	//
	// 	&:hover {
	// 		color: fade-out(#000, 0.5);
	// 	}
	// }
}
