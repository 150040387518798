@import "mixins/_button.scss";
@import "mixins/_links.scss";

@mixin unstyled-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

/// The vw unit (viewport width) is equal to 1/100th or 1% of the width of the viewport.
/// In the example below, if the width of the viewport is 1000px, the font size of the h1 will be 40px.
@function get-vw($size, $max: false, $min: false, $context: $container-width-xlg) {
    $vw-context: ($context * .01); // 1% viewport width
    @return ($size / $vw-context) + vw;
}

@function get-vh($size, $context: $container-width-xlg) {
    $vh-context: ($context * .01); // 1% viewport width
    @return ($size / $vh-context) + vh;
}

@mixin horizontal-align($percent: 50%) {
    position: relative;
    left: 50%;
    transform: translateX($percent * -1);
}

@mixin vertical-align($percent: 50%) {
    position: relative;
    top: 50%;
    transform: translateY($percent * -1);
}

@mixin calc-vw($size, $min-size: false, $min-breakpoint: false, $max-breakpoint: $container-width-xlg) {
  font-size: get-vw($size);

  //Prevent font-size from getting too small
  @if $min-size != false {
    @if type-of($min-breakpoint) == false {
        @warn "`#{$min-breakpoint}` is not defined.";
    }

    $small-screen: "(max-width: #{$min-breakpoint})";
    @media #{$small-screen} {
        @include calc-rem($min-size);
    }
  }

  // Prevent font-size from getting too big
  $wide-screen: "(min-width: #{$max-breakpoint}px)";
  @media #{$wide-screen} {
      @include calc-rem($size);
  }
}

/// Similar to vw, the vh unit (viewport height) is equal to 1/100th or 1% of the height of the viewport.
/// In this example, if the viewport height is 700px, the max-height of the image will be 175px (25×700/100 = 175 ).
@function calc-vh($size, $context: $base-font-context) {

}

/// Test if `$value` is a valid direction
/// @param {*} $value - Value to test
/// @return {Bool}
@function is-direction($value) {
  $is-keyword: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value) == 'number' and index('deg' 'grad' 'turn' 'rad', unit($value));

  @return $is-keyword or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
/// @throw Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.;
@function legacy-direction($value) {
  @if is-direction($value) == false {
    @error "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be a direction.";
  }

  $conversion-map: (
    to top          : bottom,
    to top right    : bottom left,
    to right top    : left bottom,
    to right        : left,
    to bottom right : top left,
    to right bottom : left top,
    to bottom       : top,
    to bottom left  : top right,
    to left bottom  : right top,
    to left         : right,
    to left top     : right bottom,
    to top left     : bottom right
  );

  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }

  @return 90deg - $value;
}

@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction) == false {
    $color-stops: $direction, $color-stops;
    $direction: 180deg;
  }

  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

/// Clearfix extend
/// @example scss - Usage
/// .foo {
/// @extend %clearfix;
/// }

%clearfix {
    *zoom: 1;

    &:before, &:after {
        content: ' ';
        display: table;
    }

    &:after {
        clear: both;
    }
}

/// Repsonsive image extend
/// @example scss - Usage
/// .foo {
/// img {
/// @extend %img-responsive;
/// }
/// }
%img-responsive {
    max-width: 100%;
    height: auto;
}

/// Given the location of a webfont, will generate a font-face declaration with multiple file formats.
/// @param {String} $font-name - Font family name
/// @param {String} $file-name - File name (no extension)
/// @param {String | Number} $weight [normal] - Font weight
/// @param {String} $style [normal] - Font style

/// @example scss - Usage
/// @include font-face('gotham', '/fonts/gotham');
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url($file-name + '.eot');
    src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
         url($file-name + '.woff') format('woff'),
         url($file-name + '.ttf')  format('truetype'),
         url($file-name + '.svg##{$font-name}')  format('svg');
    font-weight: $weight;
    font-style: $style;
  }
}

/// Create rounded corners
/// @param {Number} - Arc value, can be px or percent
/// @example scss - Usage
/// @include rounded-corners(50%);
@mixin rounded-corners($arc) {
	-moz-border-radius: $arc;
	-webkit-border-radius: $arc;
	border-radius: $arc;
	-khtml-border-radius: $arc;
}

/// Convert px font-size to em
/// @param {Number} $px - Font size (18px)
/// @param {Number} $context - Context font size (18px)
/// @example scss - Usage
///font-size: calc-em(18px, 20px);
@function calc-em($size, $context: $base-font-context) {
    @return ($size / $context) * 1em;
}

@mixin calc-rem($size, $context: $base-font-context) {
  font-size: $size + px;
  font-size: ($size / $context) * 1rem;
}

/// Create a text-shadow
@mixin letterpress($shadow){
    text-shadow: mix(black, $shadow, 15%) 0 1px 0;
}

/// Create a small badge - text with background (My Inbox [10])
/// @example scss - Usage
/// @include badge(#000, #fff);
@mixin badge($background, $color, $width: false, $height: false) {
    @include rounded-corners(50%);
    @include letterpress($background);
    @include linear-gradient($background, mix(black, $background, 20%));

    background-color:$background;
    color: $color;

    position: relative;
    top: -2px;

    //font-size: 80%;
    vertical-align: middle;
    margin-left: 5px;

    display: inline-block;
    text-align: center;
    padding: 0.5em 0.8em;
    padding: 3px 7px;
    line-height: 1;

    white-space: nowrap;
    vertical-align: baseline;
}

@mixin box-shadow( $horiz : .5em , $vert : .5em , $blur : 0px , $spread : 0px , $color : #000000 ){
  -webkit-box-shadow: $horiz $vert $blur $spread $color;
  -moz-box-shadow: $horiz $vert $blur $spread $color;
  box-shadow: $horiz $vert $blur $spread $color;
}



@mixin debugmessage($message, $color: rgba(255,255,0, 0.6), $color_text: #000000 ){
    &::after{
        content: $message; background: #{$color}; color: #{$color_text};
        font-family: sans-serif; font-weight: bold; display: block; text-align: center; height: 30px; line-height: 30px;
        position: fixed; right: 0; left: 0; bottom: 0; z-index: 999;
    }
}
