.c-form{
  padding-bottom: 80px;
  &_wrapper{
    @extend %layout;
  }
  form{
    max-width:span(16);
    .frm_submit {
      display: inline-block;
      width: 100%;
      float: right;
      clear: none;
      text-align: left;
      margin-top: 20px;
      .frm_button_submit {
        @extend %form_submit;
      }
      @include breakpoints($xs-only, $sm-only) {
        float: none;
      }
    }
    @include breakpoints($xs-only,$sm-only){
      max-width:none;
    }
  }
  .frm_dropzone.dz-clickable.frm_single_upload{
    max-width: none;
  }
}
