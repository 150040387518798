.c-block-_2col{

    @extend .c-block-_3col;

    .item {
        text-align:left;
        @include gallery(12 of 24);
        padding-left:50px;
        padding-right:50px;
        #{headings(1,6)} {

            margin-bottom:25px;

        }
        @include breakpoints($xs-only,$sm-only){
            width:span(24);
            margin-left:0!important;
            margin-right:0!important;
            margin-bottom:20px;
            text-align: center;
            padding-left:20px;
            padding-right:20px;

        }


    }

    /*
    .item {
        text-align:left;

    margin-left:0!important;
    margin-right:0!important;
    width: span(11);

    &:first-child{
        margin-right:span(2);
    }
    display: inline-block;
    vertical-align: middle;
    float: none;

    #{headings(1,6)} {

        margin-bottom:25px;

    }
    @include breakpoints($xs-only,$sm-only){
        width:span(24);
        margin-left:0!important;
        margin-right:0!important;
        margin-bottom:20px;
        text-align: center;
        padding-left:20px;
        padding-right:20px;

    }


}
    */

}