.c-slider {
  position: relative;
  overflow: hidden;
  @extend .animated;
  @extend .fadeIn;
  background-color:$global-background;
  #{headings(1,6)} {
    text-transform: uppercase;
    color: #fff;
    @include calc-rem(50);
    margin: 0 0 35px 0;
    line-height: 1.2;
    font-family: $font-title;
    font-weight: $bold;
    color: $global-inverse-color;

    small {
      font-family: $font-regular;
      font-weight:$bold;
      display: block;
      margin-top:30px;
      @include calc-rem(25);
      text-transform:none;
    }

    @include breakpoints($xs-only,$sm-only){
      @include calc-rem(30);
      small{
        @include calc-rem(20);
      }
    }
  }
  h2{
    @include calc-rem(40);
    line-height: 1.4;
    text-shadow: 0px 0px 4px rgba(0, 45, 98, 0.4);
    margin: 0;
    letter-spacing: -1px;
    span{
      @include calc-rem(90);
      display: block;
      line-height: 0.85;
      letter-spacing: -3px;
    }
    @include breakpoints($sm-only){
      @include calc-rem(30);
      span{
        @include calc-rem(55);
      }
    }
    @include breakpoints($xs-only,$sm-only){
      @include calc-rem(25);
      span{
        @include calc-rem(40);
      }
    }
  }
  .img{

    @include breakpoints($xs-only,$sm-only,$md-only,$lg-only){
      display:none;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    margin: 0 auto;
    width:100%;
  }

  .item{
    &:focus{
      outline:none;
    }
  }

  .body {
    position: absolute;
    top:0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    @include breakpoints($xlg-only){
      background-image:none!important;
    }
    &__wrapper {
      @extend %layout;
    }
    .box{
      float: right;
      width:span(12);
      @include breakpoints($xs-only,$sm-only){
        width:span(24);
      }
    }
    @include breakpoints($sm-only,$md-only,$lg-only){
      position: relative;
      top: 0;
      padding:200px 0 200px;
      transform: none;
      background-size: cover;
      background-position: center;
      .box{
        position: relative;
        top: auto;
        transform: translateY(0%);
      }

    }
    @include breakpoint($xs-only){
      position: relative;
      top: 0;
      background-color: $global-background-blue;
      background-size: auto 370px;
      background-position: right 20% top;
      padding: 390px 0 20px;
      text-align: center;
      transform: none;
      .box{
        position: relative;
        top: auto;
        transform: translateY(0%);
      }
    }
    @media (max-width:530px){
      background-size: auto 260px;
      padding: 280px 0 20px;
    }

  }
}
