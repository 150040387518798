.c-block-_related{
  padding:80px 0 90px;

  @include breakpoints($sm-only,$xs-only){
    padding: 50px 0;
  }

  .body{
    @include calc-rem($base-font-context);
    #{headings(1,6)}{
      text-align:center;
      color:scheme-color('primary','support');
    }
  }

  .items{
    background-size:cover;
    background-position:center center;
    @include calc-rem($base-font-context);
    &__wrapper{
      @extend %layout;
    }
    .slick-arrow{
      &:before{
        font-family:FontAwesome;

      }
    }
    .slick-prev{
      left:-18px;
      &:before{
        color:scheme-color('primary','support');
        content:'\f053';
      }
    }
    .slick-next{
      right:-18px;
      &:before{
        color:scheme-color('primary','support');
        content:'\f054';
      }
    }
  }
  .related_btn{
    clear: both;
    text-align: center;
    padding-top: 30px;
  }
  .slides_nav{
    &__wrapper{
      @extend %layout;
      /*max-width:90%;
      margin:0 auto;
      @extend %clearfix;
      @include breakpoints($xlg-only){
      max-width:1200px;
    }*/
  }
  @include breakpoints($md-only,$sm-only,$xs-only){
    display:none;
  }
  .slick-list{
    overflow:visible;
  }
  color:scheme-color('primary','base');
  .item{
    padding-right:70px;
    padding-top:85px;
    padding-bottom:50px;
    text-align:center;
    position:relative;
    opacity:0.3;
    cursor:pointer;
    &.slick-current{
      opacity:1;
      &:before{
        content:"";
        position:absolute;
        bottom:100%;
        right:0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 27.5px 28px 27.5px;
        border-color: transparent transparent scheme-color('primary','light') transparent;
        left: 34%;
        transform:translateX(-50%);
        //background:scheme-color('primary','light');
      }
      .media{
        img{
          background:scheme-color('primary','support');
        }

      }
    }

    .media{
      position:relative;

      img{
        max-width:100%;
        height:100%;
        width:100%;
        position: relative;
        z-index: 2;
        //background: #fff;
        background:scheme-color('primary','light');
        border-radius:50%;

      }
      &:before{
        content: attr(data-support);
        display:inline-block;
        vertical-align: bottom;
        position: absolute;
        bottom: 100%;
        left: -10%;
        font-family:$font-bold;
        @include calc-rem(18);
        right: 0;
        width:120%;
      }
      &:after{
        content:'';
        background:scheme-color('primary','base');
        height:3px;
        width:70px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        left: 100%;

      }
    }
    .body{
      font-family:$font-light;
      @include calc-rem(19);
      margin-top:10px;
      strong{
        font-family:$font-bold;
        display:block;
      }
      @include breakpoints($xs-only,$sm-only){
        text-align: center;
        ul{
        		text-align: left;
        		display: inline-block;
        }
      }
    }

    &:focus{
      outline:none;
    }
    &:last-child{
      .media{
        &:after{
          background:scheme-color('primary','light');
        }
      }
    }

  }
}
&.content-clients{
  position: relative;
  overflow: hidden;
  @extend .fadeIn;
  color: scheme-color('primary','light');

  .items{
    .slick-track{
      height: 9rem;
    }
    .item {
      text-align: center;
      position: relative;
      padding: 40px;
      &:focus {
        outline: none;
      }
      img {
        max-width: 100%;
        height: auto;

        position: relative;
        top: 50%;
        transform: translateY(-50%);

      }
      /*.media {

      display: inline-block;
      vertical-align: middle;
      margin-right:40px;

      position: relative;
      top: 50%;
      transform: translateY(-50%);

      img {
      max-width: 100%;
      height: auto;

    }

  }*/
  .body {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    max-width: 850px;
    //max-width:800px;
    @include calc-rem(18);
    @include breakpoints($xs-only, $sm-only) {

      text-align: center;
    }
    p {
      margin-bottom: 15px;
    }
  }
}
}
}

&.content-post{
  position: relative;
  overflow: hidden;
  @extend .fadeIn;
  background: scheme-color('primary','grey');
  padding-top:135px;
  .body{

    #{headings(1,6)}{
      color:$global-color;
      position:relative;
      z-index:99;
    }
  }
  .items{
    margin-top:70px;
    &:before{
      content:"";
      background:url('#{$image-dir}/news-bg.png');
      width:454px;
      height:472px;
      position:absolute;
      right: -2%;
      bottom: 45%;

      @include breakpoints($sm-only,$md-only){
        right: -50px;
        bottom: 30%;
      }
      @include breakpoints($xs-only){
        display:none;
      }

    }
    .item{
      background: scheme-color('primary','light');
      margin:0 1.5%;
      padding:60px 100px;
      .media {
        width: span(8);
        margin-right:span(2);
        display: inline-block;
        vertical-align: middle;
        text-align: right;
        @include breakpoints($xs-only,$sm-only){
          width: span(24);
          padding-right:0;
          margin-right:0;
          display: table-header-group;
          text-align: center;
          margin-bottom:20px;
          img {
            margin-bottom:20px;
          }
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
      .body {
        @include calc-rem($base-font-context);
        width: span(14);
        display: inline-block;
        color:scheme-color('primary','base');
        vertical-align: middle;
        text-align: left;
        @include breakpoints($xs-only,$sm-only){
          width: span(24);
          display: table-footer-group;

        }

        #{headings(1,6)}{
          text-align:left;
          @include calc-rem(30);

        }
        .date{
          font-family: $font-title;
          color:scheme-color('primary','support');
          @include calc-rem(30);
        }

      }

      @include breakpoints($xs-only,$sm-only){
        padding: 20px;
        margin:0 10px;

        .body{
          #{headings(1,6)}{
            @include calc-rem(20);
          }
          .date{
            @include calc-rem(20);
          }
        }
      }
    }
  }

}
&.content-produit{
  .body{
    #{headings(1,6)}{
      color:scheme-color('primary','blue-dark');
      @include breakpoints($xs-only,$sm-only){
        line-height: 1em;
      }
    }
    h2{
      margin-bottom: 30px;
      &:after{
        display: none;
      }
    }
    p{
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 40px;
    }
    @include breakpoints($xs-only,$sm-only){
      text-align: center;
      ul{
          text-align: left;
          display: inline-block;
      }
    }
  }
  .items{
    overflow: hidden;
    background: url('#{$image-dir}/home-produit-bg.svg')no-repeat center/70% auto;
    .item{
      @include gallery(8 of 24);
      text-align:center;
      margin-top:80px;
      @extend .animated;
      @extend .fadeIn;
      .media {
        margin:0 auto;
        height: 330px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint($sm-only){
          height: 250px;
        }
        @include breakpoints($xs-only){
          height: 150px;
        }
        img{
          height:auto;
          max-height: 300px;
          max-width: 300px;
          @include breakpoints($xs-only,$sm-only){
            max-width: 100%;
          }
          @include breakpoints($xs-only){
            max-height: 150px;
          }
        }
      }
      .body{
        @include calc-rem(18);
        margin-top: 30px;
        #{headings(1,6)}{
          @include calc-rem(24);
          font-weight: $bold;
          text-transform:none;
          color: scheme-color('primary','blue-light');
        }
        .position{
          font-family: $font-title;
          font-weight: $medium;
        }
        .phone{
          font-family: $font-title;
          font-weight: $bold;
          a{
            font-weight: normal;
          }
        }
        a{
          //@include calc-rem(25);
          &:link,&:visited{
            color:$global-color;

          }
          &:hover,&:focus{
            color:$global-emphasis-color;
          }
        }

      }
      @include breakpoints($xs-only, $sm-only){
        margin-top: 50px;
      }
    }

    &.items-frontpage{
      .item{
        margin-top: 20px;

        .media{
          img{
            height: auto;
            max-width: 98%;
          }

          @include breakpoints($xs-only,$sm-only,$md-only){
            display: block;
            height: auto;
          }
        }

        .body{
          margin-top: 10px;

          h3{

            @include breakpoints($xs-only,$sm-only,$md-only){
              @include calc-rem(18);
            }
          }
        }
      }
    }

    @include breakpoints($xs-only,$sm-only){
      background-size: contain;
      background-position: top center;
    }
  }
  @include breakpoints($xs-only,$sm-only){
    .item{
      width:span(24);
      margin-left:0!important;
      margin-right:0!important;

    }
  }
  &-taxo{
    padding-top: 0;
    padding-bottom: 40px;
    .item{
      margin-top:0;
      margin-bottom: 50px;
    }
    h2{
      color:$global-inverse-color;
      position: relative;
      clear: both;
      margin-bottom: 60px;
      display: table;
      line-height: 125px;
      padding-right: 50px;
      @extend .animated;
      @extend .fadeInLeft;
      &:before{
        content: "";
        position: absolute;
        left: -100vw;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $global-color;
        background-image: url('#{$image-dir}/bg_repeat.png');
        background-repeat: repeat;
        background-position: top left;
        z-index: -1;
      }
      @include breakpoints($xs-only,$sm-only){
        line-height: 1;
        padding: 20px 20px 20px 0;
        margin-bottom: 30px;
      }
    }
  }
}

&.content-nouvelle{
  padding-top:0;
  .body{
    #{headings(1,6)}{
      color:scheme-color('primary','blue-dark');
    }
    h2{
      margin-bottom: 0px;
      color:scheme-color('primary','blue-dark');
      &:after{
        display: none;
      }
    }
    p{
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 40px;
    }
    @include breakpoints($xs-only,$sm-only){
      text-align: center;
      ul{
          text-align: left;
          display: inline-block;
      }
    }
  }
  .items{
    overflow: hidden;
    .item{
      @include gallery(8 of 24);
      margin-bottom:15px;
      border: 6px solid #f2f2f2;
      display: block;
      @extend .animated;
      @extend .fadeIn;
      .media {
        margin:0 auto;
        height: 330px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          max-width:100%;
          height:auto;
        }
      }
      a{
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all ease 0.5s;
        .body{
          @include calc-rem(18);
          #{headings(1,6)}{
            @include calc-rem(24);
            font-weight: $bold;
            text-transform:none;
            color: scheme-color('primary','blue-dark');
            margin-bottom: 0;
          }
          .date{
            color:scheme-color('primary','blue-dark');
            margin-bottom: 50px;
          }
          span{
            color:scheme-color('primary','yellow');
            text-decoration: underline;
            font-weight: bold;
          }
        }
        &:hover{
          background: #f9f9f9;
        }

      }
      
      @include breakpoints($xs-only,$sm-only){
        width: span(24);  
      }
    }
  }
  @include breakpoints($xs-only,$sm-only){
    .item{
      width:span(24);
      margin-left:0!important;
      margin-right:0!important;

    }
  }
  &-taxo{
    padding-top: 0;
    padding-bottom: 40px;
    .item{
      margin-top:0;
      margin-bottom: 50px;
    }
    h2{
      color:$global-inverse-color;
      position: relative;
      clear: both;
      margin-bottom: 60px;
      display: table;
      line-height: 125px;
      padding-right: 50px;
      @extend .animated;
      @extend .fadeInLeft;
      &:before{
        content: "";
        position: absolute;
        left: -100vw;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $global-color;
        background-image: url('#{$image-dir}/bg_repeat.png');
        background-repeat: repeat;
        background-position: top left;
        z-index: -1;
      }
      @include breakpoints($xs-only,$sm-only){
        line-height: 1;
        padding: 20px 20px 20px 0;
        margin-bottom: 30px;
      }
    }
  }
}

&.content-document{
  padding-top:0;
  .link-privee{
    margin-top: 0;
    margin-bottom: 50px;
    i{
      width: 30px;
      height: 30px;
      line-height: 30px;
      display: inline-block;
      margin-right: 15px;
      border-radius: 50%;
      text-align: center;
      background: $global-background;
      color:scheme-color('primary','blue-dark'); 
      @include breakpoints($xs-only,$sm-only){
        margin-right: 5px;
      }
    }
  }
  .body{
    #{headings(1,6)}{
      color:scheme-color('primary','blue-dark');
    }
    h2{
      margin-bottom: 0px;
      color:scheme-color('primary','blue-dark');
      &:after{
        display: none;
      }
    }
    p{
      text-align: center;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 40px;
    }
    @include breakpoints($xs-only,$sm-only){
      text-align: center;
      ul{
          text-align: left;
          display: inline-block;
      }
    }
  }
  .items{
    overflow: hidden;
    .item{
      @include gallery(8 of 24);
      margin-bottom:15px;
      border: 6px solid #f2f2f2;
      display: block;
      @extend .animated;
      @extend .fadeIn;
      .media {
        margin:0 auto;
        height: 330px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          max-width:100%;
          height:auto;
        }
      }
      a{
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        transition: all ease 0.5s;
        .body{
          @include calc-rem(18);
          #{headings(1,6)}{
            @include calc-rem(24);
            font-weight: $bold;
            text-transform:none;
            color: scheme-color('primary','blue-dark');
            margin-bottom: 0;
          }
          .date{
            color:scheme-color('primary','blue-dark');
            margin-bottom: 50px;
          }
          span{
            color:scheme-color('primary','yellow');
            text-decoration: underline;
            font-weight: bold;
          }
        }
        &:hover{
          background: #f9f9f9;
        }

      }
      
      @include breakpoints($xs-only,$sm-only){
        width: span(24);
      }
    }
  }
  @include breakpoints($xs-only,$sm-only){
    .item{
      width:span(24);
      margin-left:0!important;
      margin-right:0!important;

    }
  }
  &-taxo{
    padding-top: 0;
    padding-bottom: 40px;
    .item{
      margin-top:0;
      margin-bottom: 50px;
    }
    h2{
      color:$global-inverse-color;
      position: relative;
      clear: both;
      margin-bottom: 60px;
      display: table;
      line-height: 125px;
      padding-right: 50px;
      @extend .animated;
      @extend .fadeInLeft;
      &:before{
        content: "";
        position: absolute;
        left: -100vw;
        right: 0;
        top: 0;
        height: 100%;
        background-color: $global-color;
        background-image: url('#{$image-dir}/bg_repeat.png');
        background-repeat: repeat;
        background-position: top left;
        z-index: -1;
      }
      @include breakpoints($xs-only,$sm-only){
        line-height: 1;
        padding: 20px 20px 20px 0;
        margin-bottom: 30px;
      }
    }
  }
}
&.content-postes{
  background:scheme-color('primary','grey');
  text-align:center;

  .body{
    @include calc-rem($base-font-context);
    max-width:1000px;
    margin:0 auto;
    #{headings(1,6)}{
      color:scheme-color('primary','base');

    }
  }
  .items{
    #{headings(1,6)}{
      font-family: $font-regular;
      font-weight: $bold;
      @include calc-rem(30);
      text-transform:none;
      margin:30px 0;
    }
    .item{
      .body{
        @include calc-rem($base-font-context);
        width:span(12);
        margin:0 auto;
        a{
          @include calc-rem(25);
          text-decoration: underline;
          &:link,&:visited{
            color:scheme-color('primary','base');

          }
          &:hover,&:focus{
            color:scheme-color('primary','support');
          }
        }
      }
    }
  }
  @include breakpoints($xs-only,$sm-only){
    .items{
      .item{
        .body{
          width:span(24);
        }
      }
    }

  }
}
&.content-cases{
  @extend %block-layout;
}
}


.page-id-168{
  .c-block-_related.content-produit .items{
    background-position: top center;
    background-size: 60% auto;
  }
}

.post-password-form{
  padding-top: 80px;
  width:span(12);
  margin:0 auto;
  font-size: 18px;
  p:nth-of-type(1){
    font-size: 28px;
    color:scheme-color('primary','blue-dark');
  }
  label{
    color:scheme-color('primary','blue-dark');
    font-size: 20px;

    display: block;
    input[type=password]{
      font-size: 20px;
      margin-top: 5px;
    }
  }

  input[type=submit]{
    margin-top: 10px;
    margin-bottom: 80px;
    display: inline-block;
    @include calc-rem(22);
    outline: none;
    padding: 15px 60px;
    position: relative;
    text-decoration: none;
    background: $button-background;
    border: 1px solid $button-border;
    color:$button-color;
    text-transform: uppercase;
    font-weight: $bold;
    @include breakpoints($xs-only,$sm-only){
      @include calc-rem(15);
      padding: 12px 30px;
    }
  }
    
}