.c-pagination{
    &__wrapper{

        .pagination {
            @include calc-rem($base-font-context);
            clear: both;
            @include links ($link: scheme-color('primary','base'), $visit: scheme-color('primary','base'), $hover: scheme-color('primary','support'), $active: scheme-color('primary','support'));
            text-align: center;
            &_wrapper {

            }

            .page-num {
                text-transform:uppercase;
               // font-family:$font-title-reg;
            }

            a, span.current {
                display: inline-block;
                //border: 1px solid gray;
                padding: 12px 18px;

            }

            span.current {
                background: scheme-color('primary','support');
                color: #fff;
                display:none;
            }
        }
    }
}