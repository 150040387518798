%input {
	background: #F2F2F2;
	resize: none;
	outline: none;
	@include calc-rem(16);
	width: 100%;
	padding: 16px;
	font-family: $font-regular;
	border-radius: 0;
}

fieldset {
	border: 0;
	padding: 0;
	margin: 0;
	font-size: 0;
}

input[type=text], input[type=tel], input[type=email],input[type=password],input[type=number],textarea,select {
	@extend %input;
}

label {
	@include calc-rem(12);
	font-family: $font-bold;
	text-transform: uppercase;
	display: none;
}
textarea{
	min-height: 320px;
}
select{
	height: 56px;
	text-indent: 9px;
}
.form-field {
	@include calc-rem($base-font-context);
	padding-bottom: span(0.5 inside no-gutters);
}
.frm_pos_none{
	display: none;
}
.frm_other_input{
	margin-top: 15px;
}
.frm_half {
	width: span(12 inside no-gutters);
	display: inline-block;
	padding-left: span(0.25 inside no-gutters);

	&.frm_first {
		padding-right: span(0.25 inside no-gutters);
		padding-left: 0;
	}
}
%form_submit{
	background :transparent;
	border-color:$global-background-yellow;
	background : $global-background-yellow;
	border-width: 1px;
	border-style: solid;
	font-family:normal;
	@include calc-rem(18);
	color : scheme-color('primary','light');;
	font-family: $font-title;
	margin-top:0;
	margin-bottom:0;
	text-align:center;
	border-radius:0;
	box-shadow:none;
	padding: 15px 80px;
	text-transform: uppercase;
	font-weight: $bold;
	&:hover{
		background : $global-background-yellow-hover;
		color: $global-inverse-color;
	}

}
.frm_form_field{
	margin-bottom:15px;
}
%form_label{
	text-align:left;
	font-family:$font-regular;
	@include calc-rem(12);
	color :  scheme-color('primary', 'base');
	text-transform:none;
	padding-left:10px;
	text-transform: uppercase;


}
.frm_message{
	color:scheme-color('primary', 'base');
	@include calc-rem(20);
	font-family:$font-regular;
}
.frm_error{
	//color:scheme-color('primary', 'light');
	@include calc-rem(20);
	font-family:$font-regular;
	color:darkred;
}

%form_setup{
	//padding: 0 155px;

	display: inline-block;
	vertical-align: middle;

	form{
		text-align:center;
		padding: 0px;
		//background :  scheme-color('primary', 'gray');
		.frm_form_title{
			margin:0;
			padding : 10px 55px;
			@include calc-rem(20);
			color :  scheme-color('primary', 'base');
			font-family:$font-medium;
			background :  scheme-color('primary', 'support');
			text-transform:none;
			margin-bottom:10px;
			@include breakpoints($xs-only,$sm-only){
				padding : 10px 15px;
			}
		}
		.form-field{
			padding:0 25px;
			@include breakpoints($xs-only,$sm-only){
				padding:0 5px;
			}
		}
		.frm_description{
			//padding : 0 105px;
			max-width:375px;
			margin:0 auto;
			p{
				text-align:center;
				font-family:$font-regular;
				color :  scheme-color('primary', 'base');
				@include calc-rem(14);
			}

		}
		.frm_primary_label{
			@extend %form_label;
		}
		.frm_button_submit{
			@extend %form_submit;
		}

	}
}
