.c-footer{
  background: $global-background-blue-dark;
  padding:60px 0 40px;
  @include breakpoints($xs-only,$sm-only){
    text-align: center;
  }
  &__wrapper{
    @extend %layout;
    @extend .animated;
    @extend .fadeIn;
  }
  .item{
    @include calc-rem($base-font-context);
    position: relative;
    color: $global-inverse-color;
    font-family: $font-regular;
    vertical-align: top;
    &.contact{
      @include calc-rem(14);
      //@include gallery(6 of 24);
      width:span(7);
      display:inline-block;
      #{headings(1,6)}{
        font-weight:$bold;
        @include calc-rem(18);
        color:scheme-color('primary','yellow');
        text-transform: none;
        strong{
          @include calc-rem(20);
          font-weight: $bold;
          text-transform: uppercase;
          a{
            &:link,&:visited{
              color:scheme-color('primary','yellow');
            }
            &:hover,&:focus{
              color:scheme-color('primary','yellow-hover');
            }
          }
        }
      }
      a{
        &:link,&:visited{
          color:$global-inverse-color;
        }
        &:hover,&:focus{
          color:$global-emphasis-color;

        }
      }
      .address{
        margin-bottom:10px;
      }
      .phone_number + .phone_title{
        &:before{
          content: "|";
          margin: 0 10px;
          @include breakpoints($xs-only){
            content: "";
            margin: 0;
            display: block;
          }
        }
      }
      .to-reach{
        strong{
          @include calc-rem(24);
          font-weight: normal;
        }
      }
      @include breakpoints($xs-only,$sm-only){
        width:span(24);
        margin-bottom:25px;
      }
    }
    &.navigation{
      //@include gallery(10 of 24);
      width:span(12);
      display:inline-block;
      ul{
        padding:0;
        margin: 0 span(1);
        @include breakpoints($xs-only,$sm-only){
          margin: 0;
        }
        li {
          width: 46%;
          float: left;
          margin-left: 2%;
          margin-right: 2%;
          list-style: none;
          padding: 0;
          @include breakpoints($xs-only,$sm-only){
            margin: 0;
          }
          a{
            &:link,&:visited{
              color:$global-inverse-color;
              @include calc-rem(18);
              margin-bottom: 10px;
              display: inline-block;
            }
            &:hover,&:focus{
              color:$global-emphasis-color;

            }
          }
          @include breakpoints($xs-only,$sm-only){
            width:span(24);
            margin-bottom:15px;
            display: none;
          }
        }
      }
      @include breakpoints($xs-only,$sm-only){
        width:span(24);
        margin-bottom:25px;
      }
    }
    &.social{
      // @include gallery(6 of 24);
      width:span(5);
      display:inline-block;
      #{headings(1,6)}{
        font-weight:normal;
        @include calc-rem(24);
        color:$global-inverse-color;
        text-transform: none;
        a{
          margin-right:20px;
          @include calc-rem(36);
          text-decoration: underline;
          &:link,&:visited{
            color:$global-inverse-color;
          }
          &:hover,&:focus{
            color:$global-emphasis-color;
          }
        }
        span{
          @include calc-rem(18);
          display: block;
        }
      }
      @include breakpoints($xs-only,$sm-only){
        width:span(24);
        #{headings(1,6)}{
          @include calc-rem(20);
          a{
            @include calc-rem(35);
          }
        }
      }
      & .apps{
        margin-top: 20px;
        h3{
          margin-bottom: 20px;
        }
        & .app{
          a{
            color: $global-inverse-color;
            &:hover,
            &:focus{
              color: $global-emphasis-color;
            }
          }
          img{
            max-width: 50px;
          }
          &+.app{
            margin-top: 15px;
          }
        }
        
      }
    }
  }
}
