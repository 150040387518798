.c-page-contact{
  padding-top: 80px;
  &_heading{
    @extend .animated;
    @extend .fadeInLeft;
    h2{
      color: scheme-color('primary','blue-dark');
    }
    @include breakpoints($xs-only, $sm-only) {
      border:none;
      padding-left: 0;
    }
  }
  &_content{
    padding: 100px 0;
    display: flex;
    align-items: stretch;
    > div {
      width: 50%;
    }
    @include breakpoints($xs-only, $sm-only) {
      display: block;
      > div{
        width: 100%;
      }
    }
  }
  form {
    .frm_form_field {
      width:span(24);
      display: inline-block;
      padding: 0;
      margin-bottom: 12px;
    }
    .frm_submit {
      display: inline-block;
      width: 100%;
      float: none;
      clear: none;
      text-align: left;
      margin: 0;
      .frm_button_submit {
        @extend %form_submit;
      }

    }
  }
  .item{
    @include calc-rem(20);

    #{headings(1,6)}{
      font-family: $font-regular;
      font-weight: $bold;
      @include calc-rem(25);
    }
    p{
      margin:0;
    }
    strong{
      font-weight: $bold;
      display:block;
      margin-bottom: 50px;
      font-family: $font-title;
      @include calc-rem(34);
    }
    p{
      @include calc-rem(24);
    }
    &.body{
      @include calc-rem(16);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $global-inverse-color;
      background-color: $global-background-secondary;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      background-image: url('#{$image-dir}/cleral-contact-image.svg');
      text-align: center;
      min-height: 500px;

    }
    &.form{
      padding-left: 20px;
      @include breakpoints($xs-only, $sm-only) {
        padding-left: 0;
        margin-top: 20px;
      }
    }
    .phone{
      margin-top:20px;
    }
    .social{
      ul{
        padding:0;
        li{
          list-style: none;
          display:inline-block;

          a{
            @include calc-rem(40);
            margin-right:5px;
            &:link,&:visited{
              color:$global-color;
            }
            &:hover,&:focus{
              color:$global-emphasis-color;
            }
          }
        }
      }
    }
  }
}

.detaillants_wrapper{
  position: relative;
  background: url('#{$image-dir}/home-produit-bg.svg') no-repeat;
  background-position: center -400px;
  background-size: 75% auto;
  padding-bottom: 50px;

  h2{
    text-align: center;
    color: scheme-color('primary','blue-dark');
    padding: 90px 0 0 0;
  }
  h3{
    text-align: left;
    padding-top: 40px;
    padding-bottom: 5px;
    margin-bottom: 15px;
    font-size: 24px;
    border-bottom: 2px solid #000;
    text-transform: uppercase; 
    color: scheme-color('primary','blue-light');
  }
  .detaillant_box{
    position: relative;
    width: 20%;
    display: inline-block;
    color: scheme-color('primary','blue-dark');
    margin-bottom: 10px;
    font-size: 18px;
    padding-right: 20px;
    @include breakpoints($md-only, $lg-only) {
      width: span(8);
    }
    @include breakpoints($xs-only, $sm-only) {
      width: span(24);
    }
    &_title{
      font-size: 24px;
      font-weight: $bold;
    }
    a{
      color: scheme-color('primary','blue-dark');
      text-decoration: underline;
    }
  }
}

// MAP - WINDOW //
.map_window{
	padding: 15px;
	&_title{
		font-size: 24px;
		font-weight: $bold;
		color: scheme-color('primary','blue-dark');

	}

	&_title,
	&_address,
	&_phone,
	&_email,
	&_website,
  &_responsable{
		margin-bottom: 8px;
	}

	&_button{
		margin-top: 13px;
    .c-btn{
      font-size: 18px;
      padding: 12px 30px;

    }
	}
}