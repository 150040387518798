.c-info{
  //text-align: center;
  color: $global-inverse-color;
  &_grid{
    display: flex;
    flex-wrap: wrap;
    @include breakpoints($xs-only,$sm-only){
      display: block;
    }
  }
  &:nth-of-type(odd){
    .c-info_grid{
      flex-direction: row-reverse;
    }
  }
  &_item{
    width:50%;
    height: 710px;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-repeat: no-repeat;
    background-color: $global-background-blue;
    background-size: cover; 
    background-position: center;
    @include breakpoints($xs-only,$sm-only){
      width: 100%;
      height: auto;
      padding: 50px 20px;
      text-align: center;
      ul{
      		text-align: left;
      		display: inline-block;
      }
    }
    > div{
      width: span(18);
      @extend .animated;
      @extend .fadeIn;
      @include breakpoints($xs-only){
        width: 100%;
      }
    }
    &_intro{
      
      &_blue{
        background-color: $global-background-secondary;
        background-image: url('#{$image-dir}/cleral_info_intro_bg_blue.jpg');
      }
      &_red{
        background-color: $global-background-primary;
        background-image: url('#{$image-dir}/transcomax_info_intro_bg.jpg');
      }
      .c-info_item_title{
        font-weight: $bold;
      }
    }
    &_content{
      text-align: center;
    }
    span{
      color: scheme-color('primary','yellow');
      text-transform: uppercase;
      font-weight: $bold;
      &.c-info_item_subtitle{
        color: scheme-color('primary','light');
        font-weight: normal;
      }
    }
    &_title{
      color: $global-inverse-color;
      font-weight: $bold;
      &.c-heading-primary:after{
        font-weight: bold;
        content: url('#{$image-dir}/heading_inverse_primary.svg');
      }
    }
    &_subtitle{
      @include calc-rem(24);
    }
    .c-btn{
      margin-top:40px;
      @include breakpoints($xs-only,$sm-only){
        margin-top: 20px;
      }
    }
    &_illustration{
      margin-bottom: 50px;
      @include breakpoints($xs-only,$sm-only){
        margin-bottom: 30px;
      }
    }
  }
}

.home, .page-id-162, .page-id-493{
  .c-info_item{
    &_intro{
      display: none;
    }
  }
}